import React from 'react'
import styled from 'styled-components'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import { FaTimes } from 'react-icons/fa'
import links from '../constants/links'
import socialLinks from '../constants/social_links'

const Sidebar = ({ isOpen, toggleSidebar }) => {
  return (
    <Wrapper>
      <aside className={isOpen ? 'sidebar show-sidebar' : 'sidebar'}>
        <button
          className='close-btn'
          type='button'
          onClick={toggleSidebar}
          aria-label='close sidebar button'
        >
          <FaTimes />
        </button>
        <div className='side-container'>
          <ul className={isOpen ? 'sidebar-links' : null}>
            {links.map((link, index) => {
              return (
                <li key={link.id}>
                  <AnchorLink to={link.url} onAnchorLinkClick={toggleSidebar}>
                    {link.text}
                  </AnchorLink>
                </li>
              )
            })}
          </ul>
          <ul className={isOpen ? 'social-links sidebar-icons' : null}>
            {socialLinks.map((link, index) => {
              return (
                <li key={link.id}>
                  <a
                    aria-label={link.aria || 'social media icons'}
                    href={link.url}
                    target='_blank'
                    rel='noopener noreferrer'
                    className='social-link'
                  >
                    {link.icon}
                  </a>
                </li>
              )
            })}
          </ul>
        </div>
      </aside>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .sidebar {
    background: var(--clr-bcg);
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    display: grid;
    place-items: center;
    opacity: 0;
    transition: var(--transition);
    transform: translateX(100%);
  }
  .show-sidebar {
    opacity: 1;
    transform: translateX(0);
  }
  .sidebar-links li {
    opacity: 0;
  }
  .sidebar-links li a {
    display: block;
    text-align: center;
    color: var(--clr-font);
    letter-spacing: var(--spacing);
    margin-bottom: 0.5rem;
    font-size: 2rem;
    transition: var(--transition);
    border-radius: var(--radius);
  }
  .sidebar-links li a:hover {
    background: var(--clr-primary-9);
    color: var(--clr-primary-5);
  }
  .close-btn {
    position: absolute;
    right: 4.75%;
    top: 2.75%;
    font-size: 2.5rem;
    background: transparent;
    border-color: transparent;
    color: var(--clr-font);
    cursor: pointer;
  }
  .close-btn:hover {
    color: hsl(360, 67%, 44%);
  }
  @media screen and (min-width: 992px) {
    .sidebar {
      transform: translateX(-100%);
    }
  }

  .sidebar-links li {
    animation: slideRight 0.5s ease-in-out 0.3s forwards;
  }
  .sidebar-links li:nth-of-type(1) {
    animation-delay: 0.25s;
  }
  .sidebar-links li:nth-of-type(2) {
    animation-delay: 0.5s;
  }
  .sidebar-links li:nth-of-type(3) {
    animation-delay: 0.75s;
  }
  .sidebar-links li:nth-of-type(4) {
    animation-delay: 1s;
  }
  .sidebar-links li:nth-of-type(5) {
    animation-delay: 1.25s;
  }
  @keyframes slideRight {
    0% {
      transform: translateX(200px);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
  .sidebar-icons li {
    opacity: 0;
    animation: slideDown 0.5s ease-in-out 0.3s forwards;
  }
  .sidebar-icons li a {
    color: var(--clr-grey-1);
  }
  .sidebar-icons li:nth-of-type(1) {
    animation-delay: 0.25s;
  }
  .sidebar-icons li:nth-of-type(2) {
    animation-delay: 0.5s;
  }
  .sidebar-icons li:nth-of-type(3) {
    animation-delay: 0.75s;
  }
  .sidebar-icons li:nth-of-type(4) {
    animation-delay: 1s;
  }
  .sidebar-icons li:nth-of-type(5) {
    animation-delay: 1.25s;
  }
  @keyframes slideDown {
    0% {
      transform: translateY(-200px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  .social-links {
    margin-top: 2rem;
    width: 15rem;
    display: flex;
    justify-content: space-between;
  }
  .social-icon {
    font-size: 1.75rem;
    color: var(--clr-primary);
    transition: var(--transition);
  }
  .social-icon:hover {
    color: var(--clr-hover);
  }
  .social-icon.twitter {
    transform: rotate(270deg);
  }
`

export default Sidebar
