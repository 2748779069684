import React, { useState } from 'react'
import Navbar from './Navbar'

import Sidebar from './Sidebar'
import { Helmet } from 'react-helmet'

const Layout = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false)
  const toggleSidebar = () => {
    setIsOpen(!isOpen)
  }

  return (
    <>
      <Helmet htmlAttributes={{ lang: 'en' }} />
      <Navbar toggleSidebar={toggleSidebar} />
      <Sidebar isOpen={isOpen} toggleSidebar={toggleSidebar} />
      {children}
    </>
  )
}

export default Layout
